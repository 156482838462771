import React from "react";
import HeaderSection6 from "../components/header/HeaderSection6";
import BannerSection5 from "../components/banner/BannerSection5";
import Categories from "../components/product/Categories";
import CtaSection from "../components/cta/CtaSection";
import GlassSection from "../components/product/GlassSection";
import CtaSection2 from "../components/cta/CtaSection2";
import GlassSection2 from "../components/product/GlassSection-2";
import CtaSection3 from "../components/cta/CtaSection3";
import SunGlassSection from "../components/product/SunglassSection";
import AccesoriesSection from "../components/product/AccesoriesSection";
import OfferSection4 from "../components/offer/OfferSection4";
import BrandSection2 from "../components/brands/BrandSection2";
import BlogSection5 from "../components/blog/BlogSection5";
import FeatureSection from "../components/feature/FeatureSection";
import FooterSection from "../components/footer/FooterSection";
import BottomMobileMenu from "../components/navigation/BottomMobileMenu";
 import StoreContextProvider from '../context/StoreContext';
 import { Helmet } from 'react-helmet';
 import { useTranslation } from 'react-i18next';
 



const Home = () => {
  const { i18n } = useTranslation();
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;


  return (
<>
<Helmet>
  <title>ريجل ايز | Regaleyes</title>
  <meta name="description" content="Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort." />
  <meta name="keywords" content={currentKeywords} />
  </Helmet>
    <main className="fz-5-body">
      <HeaderSection6 />
      <BannerSection5 />
      <Categories />
      <CtaSection />
      <GlassSection2 />
      <CtaSection2 />
      <GlassSection />
      <OfferSection4 />
      <SunGlassSection />
      <CtaSection3 />
      <AccesoriesSection />
      <BrandSection2 />
      <BlogSection5 />
      <FeatureSection />
      <FooterSection />
      <BottomMobileMenu
        style="fz-5-mobile-menu"
        logo="assets/images/logo-5.png"
      />
    </main>
</>
  );
};

export default Home;
