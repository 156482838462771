import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams} from 'react-router-dom';
const apiUrl = import.meta.env.VITE_API_URL;


const ProductDescTabPane = () => {
  const [product, setProduct] = useState({});
  const { slug_en } = useParams();
  const { t, i18n } = useTranslation(); 
  const [shapes, setShapes] = useState({});
  const [genders, setGenders] = useState({});
  const [powers, setPowers] = useState({});








  useEffect(() => {

    const fetchShapes = () => {
      axios.get(`${apiUrl}/api/shapes`)
        .then(response => {
          const shapesData = response.data.reduce((acc, shape) => {
            acc[shape.id] = i18n.language === 'ar' ? shape.title_ar : shape.title_en;
            return acc;
          }, {});
          setShapes(shapesData);
        })
        .catch(error => {
          console.error(error);
        });
    };
    const fetchGenders = () => {
      axios.get(`${apiUrl}/api/genders`)
        .then(response => {
          const gendersData = response.data.reduce((acc, gender) => {
            acc[gender.id] = i18n.language === 'ar' ? gender.title_ar : gender.title_en;
            return acc;
          }, {});
          setGenders(gendersData);
        })
        .catch(error => {
          console.error(error);
        });
    };
    const fetchPowers = () => {
      axios.get(`${apiUrl}/api/attributes`)
        .then(response => {
          const powerData = response.data.reduce((acc, attribute) => {
            acc[attribute.id] = i18n.language === 'ar' ? attribute.value_ar : attribute.value_en;
            return acc;
          }, {});
          setPowers(powerData);
        })
        .catch(error => {
          console.error(error);
        });
    };
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/product/${slug_en}`);
        const data = response.data;
        setProduct(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
    fetchShapes();
    fetchGenders();
    fetchPowers();
  }, [slug_en, i18n.language]); 
  return (
    <div className="fz-product-details__descr">
        <p>{i18n.language === 'ar' ? product.description_ar : product.description_en}
        </p>
  {product.color && <p>{t('Color')}: {product.color}</p>}
  {product.length && <p>{t('Length')}: {product.length}</p>}
  {product.width && <p>{t('Bridge width')}: {product.width}</p>}
  {product.gender_id && <p>{t('Gender')}: {genders[product.gender_id]}</p>}
  {product.shape_id && <p>{t('Shape')}: {shapes[product.shape_id]}</p>}
  {product.attribute_id && <p>{t('Power')}: {powers[product.attribute_id]}</p>}


    </div>
  )
}

export default ProductDescTabPane