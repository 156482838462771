import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../authentication/AuthContext';
import GoogleIcon from '../../../public/assets/images/google-icon.png';
import AppleIcon from '../../../public/assets/images/apple-icon.png';
import FacebookIcon from '../../../public/assets/images/facebook-icon.png';
import countries from './countryCodes.json';
import './OtpModalRegister.css'; 
import ReCAPTCHA from 'react-google-recaptcha';



const apiUrl = import.meta.env.VITE_API_URL;

const SignUpFormSectionPhone = () => {
  const { handleRegisterPhone, otp, setOtp, handleOtpVerification } = useContext(AuthContext);
  const [showOtpInput, setShowOtpInput] = useState(false);

  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState('966'); // Default country code
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const [loginUrlFacebook, setLoginUrlFacebook] = useState(null);
  const [loginUrlApple, setLoginUrlApple] = useState(null);
  const [capVal, setCapVal] = useState(null);
  



  const handleFormSubmit = (e) => { 
    e.preventDefault();
  
    if (!phone) {
      toast.error('Please fill out all fields.', { position: 'top-right' });
      return;
    }
  
    // Combine country code and phone number
    const fullPhoneNumber = `${countryCode}${phone}`;
  
    // Pass the combined phone number to the registration function
    handleRegisterPhone(fullPhoneNumber);
    setShowOtpInput(true); // show OTP input popup
    setPhone(''); // Clear phone input
  };


  const handleOtpSubmit = (e) => {
    e.preventDefault();
    handleOtpVerification(otp);
    navigate('/login');
  };

  const onClose = () => {
    setShowOtpInput(false); // Close the OTP modal
    setOtp(''); // Optionally clear the OTP input
  };


  useEffect(() => {
    fetch(`${apiUrl}/api/auth/google`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong!');
        })
        .then((data) => setLoginUrlGoogle( data.url ))
        .catch((error) => console.error(error));
}, []);

useEffect(() => {
    fetch(`${apiUrl}/api/auth/facebook`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong!');
        })
        .then((data) => setLoginUrlFacebook( data.url ))
        .catch((error) => console.error(error));
}, []);

useEffect(() => {
  fetch(`${apiUrl}/api/auth/apple`, {
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
  })
      .then((response) => {
          if (response.ok) {
              return response.json();
          }
          throw new Error('Something went wrong!');
      })
      .then((data) => setLoginUrlApple( data.url ))
      .catch((error) => console.error(error));
}, []);



  return (
    <>
      <form action="#" onSubmit={handleFormSubmit}>

      <div className="d-flex align-items-center">
                    <select
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="form-select me-2 p-2 bg-light"
                        style={{ borderRadius: '10px', width: "130px" }}
                    >
                        {countries.map((country) => (
                            <option key={country.code} value={country.dial_code}>
                                {country.code} ({country.dial_code})
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="phone"
                        id="login-phone"
                        placeholder={t('500000000')}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control" // Added class for styling
                        style={{ borderRadius: '10px' }} // Added inline style for consistency
                    />
                </div>
                <ReCAPTCHA 
            sitekey="6Lf1t8QqAAAAAEfBUWakU2TsJu8WU4f3ItzzmSLh"
            onChange={(val) => setCapVal(val)}
            />
        <button disabled={!capVal}  type="submit" className="fz-1-banner-btn single-form-btn">
          {t('Register')}
        </button>
      </form>
      <hr />
      <p className="text-center">Or</p>
      <div className="social-icons d-flex justify-content-center m-2">
                <a href={loginUrlGoogle} style={{ marginRight: '10px' }}>
                    <img src={GoogleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                <a href={loginUrlFacebook} style={{ marginRight: '10px' }}>
                    <img src={FacebookIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                {/* <a href={loginUrlApple} style={{ marginRight: '10px' }}>
                    <img src={AppleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a> */}
            </div>

            

            {showOtpInput && (
              <div className="otp-modal-overlay">
              <div className="otp-modal">
                  <h2 className="single-form-title">{t('Enter OTP')}</h2>
                  <form onSubmit={handleOtpSubmit}>
                      <input
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder={t('Enter OTP')}
                          className="otp-input-single"
                      />
                      <button className="btn btn-dark" type="submit">{t('Verify OTP')}</button>
                      <button type="button" onClick={onClose}>
                <i className="bi bi-x-circle" style={{ marginRight: '5px' }}></i> {t("Close")}
              </button>

                  </form>
              </div>
          </div>

      )}
    </>
  );
};

export default SignUpFormSectionPhone;
